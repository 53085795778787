/**
 * Listen for an event on a given element
 * Blur the target element if the user clicks on enter
 * Useful for forms on mobile devices
 *
 * To use it: add @keyup="hideMobileKeyboardOnReturn"
 * While importing the function in the <script></script> tag
 *
 * @param {Event} keyboardEvent Event triggered by the keyboard
 */
const hideMobileKeyboardOnReturn = keyboardEvent => {
  const key = keyboardEvent.code || keyboardEvent.keyCode;
  if (key === 'Enter' || key === 13) {
    keyboardEvent.target.blur();
  }
};
export default hideMobileKeyboardOnReturn;