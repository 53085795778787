import { render, staticRenderFns } from "./LanguageButton.vue?vue&type=template&id=08f3dee6&scoped=true&"
import script from "./LanguageButton.vue?vue&type=script&lang=ts&"
export * from "./LanguageButton.vue?vue&type=script&lang=ts&"
import style0 from "./LanguageButton.vue?vue&type=style&index=0&id=08f3dee6&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08f3dee6",
  null
  
)

export default component.exports