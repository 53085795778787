import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import itemCard from "./ItemCard.vue";
let Favorites = class Favorites extends Vue {
  get profileOpen() {
    return this.$store.state.profileOpen;
  }
  set profileOpen(value) {
    this.$store.commit("setProfileOpen", value);
  }
  get currentTab() {
    return this.$store.state.currentTab;
  }
  set currentTab(value) {
    this.$store.commit("setCurrentTab", value);
  }
  get cartRows() {
    return this.$store.state.configurator.cart.cartRows;
  }
  get configurations() {
    return this.$store.state.configurator.configurations;
  }
  get parsedConfigurations() {
    let t = [];
    this.configurations.forEach(element => {
      var _element$coloreAste, _element$colore$U_THE, _element$colore, _element$coloreAste2, _element$coloreAste3, _element$colore2, _element$colore3, _element$frontale, _element$frontale2, _element$frontale$U_T, _element$frontale3;
      t.push({
        asian_fitting: !element.asianFitting || element.asianFitting.toString() == "N" ? "NO" : "YES",
        aste: element === null || element === void 0 ? void 0 : (_element$coloreAste = element.coloreAste) === null || _element$coloreAste === void 0 ? void 0 : _element$coloreAste.U_THE_COLORE,
        calibro: element.calibro,
        colore: (_element$colore$U_THE = element === null || element === void 0 ? void 0 : (_element$colore = element.colore) === null || _element$colore === void 0 ? void 0 : _element$colore.U_THE_COLORE) !== null && _element$colore$U_THE !== void 0 ? _element$colore$U_THE : null,
        colore_aste_preview: element !== null && element !== void 0 && (_element$coloreAste2 = element.coloreAste) !== null && _element$coloreAste2 !== void 0 && _element$coloreAste2.imageUrl ? element.coloreAste.imageUrl : this.colorImageUrl((_element$coloreAste3 = element === null || element === void 0 ? void 0 : element.coloreAste) !== null && _element$coloreAste3 !== void 0 ? _element$coloreAste3 : undefined),
        colore_metallo_aste: element.coloreMetalloAste,
        colore_nome: element.coloreNome,
        colore_preview: element !== null && element !== void 0 && (_element$colore2 = element.colore) !== null && _element$colore2 !== void 0 && _element$colore2.imageUrl ? element.colore.imageUrl : this.colorImageUrl((_element$colore3 = element === null || element === void 0 ? void 0 : element.colore) !== null && _element$colore3 !== void 0 ? _element$colore3 : undefined),
        finitura: element.finitura,
        id: -1,
        modello: element === null || element === void 0 ? void 0 : (_element$frontale = element.frontale) === null || _element$frontale === void 0 ? void 0 : _element$frontale.U_THE_MODELLO,
        modello_preview: element !== null && element !== void 0 && (_element$frontale2 = element.frontale) !== null && _element$frontale2 !== void 0 && _element$frontale2.imageUrl ? element.frontale.imageUrl : this.modelImageUrl((_element$frontale$U_T = element === null || element === void 0 ? void 0 : (_element$frontale3 = element.frontale) === null || _element$frontale3 === void 0 ? void 0 : _element$frontale3.U_THE_MODELLO) !== null && _element$frontale$U_T !== void 0 ? _element$frontale$U_T : ""),
        naso: element.naso,
        nome: element.nome,
        note: element.note,
        row_state: null
      });
    });
    return t;
  }
  saveConfiguration() {
    this.$store.commit("configurator/saveConfiguration");
  }
  restoreConfiguration(id) {
    this.$store.commit("configurator/restoreConfiguration", id);
  }
  removeConfiguration(index) {
    this.$store.commit("configurator/removeConfiguration", index);
  }
  clearAllConfigurations() {
    this.$store.commit("configurator/clearAllConfigurations");
  }
  modelImageUrl(model) {
    return "https://media.thema-optical.com/3d_models/" + model + ".png";
  }
  colorImageUrl(colore) {
    if (colore && colore.Category != "MULTISTRATO") {
      return process.env.VUE_APP_COLORS_URL + colore.U_THE_COLORE + ".jpg";
    } else if (colore) {
      return "https://media.thema-optical.com/colori_acetato/frontali/no_preview/" + colore.U_THE_COLORE + ".jpg";
    }
    return "";
  }
};
Favorites = __decorate([Component({
  components: {
    itemCard
  }
})], Favorites);
export default Favorites;