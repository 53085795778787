import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import _ from "lodash";
import SamplesModelDialog from "./SamplesModelDialog.vue";
import jslinq from "jslinq";
import hideMobileKeyboardOnReturn from "../plugins/utils";
// import {Waypoint} from 'vue-waypoint'
let ColoriFrontali = class ColoriFrontali extends Vue {
  constructor() {
    super(...arguments);
    this.noImageColor = [];
    this.searchQuery = "";
    this.category = [];
    this.animateColors = false;
    this.hoverWarning = false;
    this.showSampleDialog = false;
  }
  async mounted() {
    await this.getColors();
  }
  hideMobileKeyboardOnReturn(e) {
    hideMobileKeyboardOnReturn(e);
  }
  get categories() {
    return jslinq(this.colori).select(c => c.Category).distinct().toList();
  }
  get filteredColors() {
    let q = this.searchQuery;
    let c = this.category;
    let result = this.colori;
    if (q) {
      result = result.filter(color => {
        return _.includes(color === null || color === void 0 ? void 0 : color.PLASTICA, q);
      });
    }
    if (!_.isEmpty(c)) {
      if (result) {
        result = result.filter(color => {
          return c.indexOf(color === null || color === void 0 ? void 0 : color.Category) >= 0;
        });
      }
    }
    return _.difference(result, this.noImageColor);
  }
  get finitura() {
    return this.$store.state.configurator.finitura;
  }
  set finitura(f) {
    this.$store.commit("configurator/setFinishing", f);
  }
  get colore() {
    return this.$store.state.configurator.colore;
  }
  set colore(c) {
    this.$store.commit("configurator/setColore", c);
  }
  get colori() {
    return this.$store.state.configurator.frontali.colors;
  }
  get canOrderColorSample() {
    return this.$store.getters["canOrderColorSample"];
  }
  getColors() {
    return this.$store.dispatch("configurator/frontali/getColors");
  }
  colorImageUrl(colore) {
    if (colore && colore.Category != "MULTISTRATO") {
      return colore.imageUrl;
    }
    if (colore) {
      return colore.backImageUrl;
    }
    return "";
  }
  isActive(c) {
    return this.colore == c;
  }
  removeCategoryItem(item) {
    _.pull(this.category, item);
  }
  noImageError(c) {
    this.noImageColor.push(c);
  }
  loadImageUrl(w) {
    let e = w.el;
    let src = w.el.dataset.src;
    if (w.going.toLowerCase() === "in") {
      e.src = src;
    }
  }
  onRevealSettingChanged(newVal) {
    if (newVal == "frontColor") {
      let self = this;
      this.animateColors = true;
      setTimeout(function () {
        self.animateColors = false;
        self.$emit("reset-setting-item");
      }, 2000);
    }
  }
};
__decorate([Prop({
  default: false
})], ColoriFrontali.prototype, "darkTheme", void 0);
__decorate([Prop({
  default: false
})], ColoriFrontali.prototype, "isOpen", void 0);
__decorate([Prop({
  default: false
})], ColoriFrontali.prototype, "revealSetting", void 0);
__decorate([Watch("revealSetting")], ColoriFrontali.prototype, "onRevealSettingChanged", null);
ColoriFrontali = __decorate([Component({
  components: {
    SamplesModelDialog
  },
  filters: {
    upper(val) {
      return _.upperCase(val);
    },
    capitalize: str => {
      return _.capitalize(str);
    }
  }
})], ColoriFrontali);
export default ColoriFrontali;