import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let PrintButton = class PrintButton extends Vue {
  get positionForPrint() {
    return this.$store.state.configurator.positionForPrint;
  }
  get configurationComplete() {
    return this.$store.getters["configurator/configurationComplete"];
  }
  set positionForPrint(value) {
    this.$store.commit("configurator/setPositionForPrint", value);
  }
  get print() {
    return this.$store.state.configurator.print;
  }
  set print(value) {
    this.$store.commit("configurator/setPrint", value);
  }
  snap() {
    /**
     * al click setto la variabile true per poter compilare il template e aprire la stampa
     * per poi riportarla a false a fine ciclo tramite propos / $emit
     *
     * Per posizionare correttamente l'occhiale, uso setPositionForPrint
     */
    this.positionForPrint = true;
    setTimeout(() => {
      this.print = true;
    }, 750);
  }
};
PrintButton = __decorate([Component({})], PrintButton);
export default PrintButton;