import { __decorate } from "tslib";
import { Component, Vue, Prop } from "vue-property-decorator";
let HistoryButton = class HistoryButton extends Vue {
  get profileOpen() {
    return this.$store.state.profileOpen;
  }
  set profileOpen(value) {
    this.$store.commit("setProfileOpen", value);
  }
  get currentTab() {
    return this.$store.state.currentTab;
  }
  set currentTab(value) {
    this.$store.commit("setCurrentTab", value);
  }
};
__decorate([Prop({
  default: false
})], HistoryButton.prototype, "userIsGuest", void 0);
HistoryButton = __decorate([Component({})], HistoryButton);
export default HistoryButton;