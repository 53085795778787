import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import 'vuetify/src/stylus/app.styl'
import colors from 'vuetify/es5/util/colors';
import en from 'vuetify/src/locale/en';
const opts = {
  breakpoint: {
    mobileBreakpoint: 'sm',
    scrollBarWidth: 24,
    thresholds: {
      xs: 340,
      sm: 540,
      md: 800,
      lg: 1280
    }
  },
  theme: {
    dark: false,
    default: 'light',
    disable: false,
    options: {},
    themes: {
      light: {
        background: colors.orange,
        primary: '#037b3f',
        secondary: colors.grey.darken3,
        accent: colors.red.accent1,
        error: '#C71600' /*colors.orange.darken4*/,
        warning: colors.red,
        info: colors.blueGrey.lighten3,
        success: colors.green.darken1,
        navigation: '#6c6c6c'
      },
      dark: {
        background: colors.orange,
        primary: '#037b3f',
        secondary: colors.grey.darken3,
        accent: colors.red.accent1,
        error: '#C71600' /*colors.orange.darken4*/,
        warning: colors.red,
        info: colors.blueGrey.lighten3,
        success: colors.green.darken1,
        navigation: '#6c6c6c'
      }
    }
  },
  lang: {
    locales: {
      en
    },
    current: 'en'
  },
  icons: {
    iconfont: 'md',
    values: {}
  }
};
Vue.use(Vuetify);
export default new Vuetify(opts);