import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import PersonalNamePreview from "./PersonalNamePreview.vue";
import hideMobileKeyboardOnReturn from "../plugins/utils";
let PersonalName = class PersonalName extends Vue {
  constructor() {
    super(...arguments);
    this.colors = [{
      name: "nero",
      colorCode: "#212121",
      colorClass: "grey darken-4",
      checkColor: "white"
    }, {
      name: "bianco",
      colorCode: "#fafafa",
      colorClass: "grey lighten-5",
      checkColor: "grey"
    }, {
      name: "verde",
      colorCode: "#4caf50",
      colorClass: "green",
      checkColor: "white"
    }, {
      name: "blu",
      colorCode: "#2196f3",
      colorClass: "blu",
      checkColor: "white"
    }, {
      name: "giallo",
      colorCode: "#FFEB3B",
      colorClass: "yellow",
      checkColor: "grey"
    }, {
      name: "rosso",
      colorCode: "#f44336",
      colorClass: "red",
      checkColor: "white"
    }, {
      name: "rosa",
      colorCode: "#F8BBD0",
      colorClass: "pink",
      checkColor: "white"
    }, {
      name: "arancione",
      colorCode: "#FF9800",
      colorClass: "orange",
      checkColor: "white"
    }, {
      name: "viola",
      colorCode: "#AB47BC",
      colorClass: "purple",
      checkColor: "white"
    }, {
      name: "oro",
      colorCode: "#FFD700",
      colorClass: "gold",
      checkColor: "white"
    }, {
      name: "argento",
      colorCode: "#D3D3D3",
      colorClass: "silver",
      checkColor: "white"
    }, {
      name: "azzurro",
      colorCode: "#81D4FA",
      colorClass: "light blue",
      checkColor: "white"
    }, {
      name: "",
      colorCode: "",
      colorClass: "no color",
      checkColor: ""
    }];
    this.hideMobileKeyboardOnReturn = hideMobileKeyboardOnReturn;
  }
  get nome() {
    return this.$store.state.configurator.frontali.name;
  }
  set nome(value) {
    this.$store.commit("configurator/frontali/setName", value);
  }
  get colore() {
    return this.$store.state.configurator.frontali.nameColor;
  }
  set colore(value) {
    this.$store.commit("configurator/frontali/setNameColor", value);
  }
  get selectedColor() {
    return this.colors.filter(c => {
      return c.name == this.colore;
    })[0];
  }
  textClear() {
    this.nome = "";
  }
  onNomeChange(newVal) {
    if (newVal.length == 0 || newVal == "") {
      // console.log('newVal', newVal);
      // this.coloreNome = ''
      this.colore = "";
    } else if (newVal != "" && this.colore == "") {
      this.colore = "nero";
    }
  }
};
__decorate([Prop({
  default: false
})], PersonalName.prototype, "darkTheme", void 0);
__decorate([Watch("nome")], PersonalName.prototype, "onNomeChange", null);
PersonalName = __decorate([Component({
  components: {
    PersonalNamePreview
  },
  filters: {
    upperCase(s) {
      return s.toUpperCase();
    }
  }
})], PersonalName);
export default PersonalName;