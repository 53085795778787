import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import history from "./History.vue";
import favorites from "./Favorites.vue";
import cart from "./UserCart.vue";
let Profile = class Profile extends Vue {
  constructor() {
    super(...arguments);
    this.items = ["favorites", "cart", "history"];
  }
  get currentTab() {
    return this.$store.state.currentTab;
  }
  set currentTab(value) {
    this.setCurrentTab(value);
  }
  get profileOpen() {
    return this.$store.getters["profileOpen"];
  }
  get cartRows() {
    return this.$store.state.configurator.cart.cartRows;
  }
  get orderHistory() {
    return this.$store.state.configurator.user.orderHistory;
  }
  get configurations() {
    return this.$store.state.configurator.configurations;
  }
  setProfileOpen(value) {
    this.$store.commit("setProfileOpen", value);
  }
  setCurrentTab(value) {
    this.$store.commit("setCurrentTab", value);
  }
  getUserHistory() {
    this.$store.dispatch("configurator/user/getUserHistory");
  }
  openCart() {
    this.$emit("openProfileDialog");
  }
  logout() {
    this.$emit("logout");
  }
  onCurrentTabChanged(value) {
    if (value == "tab-history" && (!this.orderHistory || this.orderHistory.length == 0)) {
      this.getUserHistory();
    }
  }
};
__decorate([Prop({
  default: false
})], Profile.prototype, "darkTheme", void 0);
__decorate([Prop({
  default: false
})], Profile.prototype, "haveConfigurations", void 0);
__decorate([Prop({
  default: false
})], Profile.prototype, "user", void 0);
__decorate([Watch("currentTab")], Profile.prototype, "onCurrentTabChanged", null);
Profile = __decorate([Component({
  components: {
    history,
    favorites,
    cart
  }
})], Profile);
export default Profile;